<script setup lang="ts">
const { isLegacyBrowser } = useDeviceDetection();

const alertClosed = ref(false);
const closeAlert = () => {
    alertClosed.value = true;
};

const alertVisible = computed(() => isLegacyBrowser.value && !alertClosed.value);
</script>

<template>
    <div
        v-if="alertVisible"
        class="my-8"
    >
        <SharedAlert
            color="brand-warning"
            class="legacy-browser-alert"
            close-target="legacy-browser"
            @click:close="closeAlert"
        >
            <template #heading>{{ $t('legacyBrowser.title') }}</template>
            <template #message>
                <p>{{ $t('legacyBrowser.text') }}</p>
            </template>
        </SharedAlert>
    </div>
</template>

<style scoped>
.legacy-browser-alert {
    border-color: #f58345;
}
</style>
